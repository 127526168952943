<template>
    <div>
        <Title heading="Congrats, you're covered!" />

        <div class="jm-content-confirm">
            <div>
                <p>You have successfully completed your application, and your jewelry is now insured.</p>
                <p v-if="!hasAllAppraisals">
                    <strong>Please send us an appraisal or detailed receipt as soon as you can</strong>
                    , to ensure exact repair or replacement in the event of a claim. You can do so through
                    <b-link :href="appraisalUploadUrl" target="_blank">
                        Appraisal Upload
                    </b-link>
                    or by emailing
                    <b-link href="mailto:personaljewelry@jminsure.com">
                        personaljewelry@jminsure.com.
                    </b-link>
                </p>
                <p>
                    An email will be sent to
                    <strong>{{ getApplicant ? getApplicant.emailAddress : '' }}</strong>
                    for your records. If you have any questions, call us at 888-346-9464.
                </p>
            </div>

            <div class="insurance-details-panel">
                <div class="insurance-details-title">
                    Your Insurance Details
                </div>
                <b-row class="applicant-summary-content">
                    <b-col cols="12" md="6" lg="4">
                        <b-row>
                            <b-col cols="7" lg="6" class="summary-label">
                                Application Number:
                            </b-col>
                            <b-col cols="4" lg="5" class="summary-value">
                                {{ account ? account.accountNumber : '' }}
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="7" lg="6" class="summary-label">
                                Policy Number:
                            </b-col>
                            <b-col cols="4" lg="5" class="summary-value">
                                Pending
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="7" lg="6" class="summary-label">
                                Total Yearly Premium:
                            </b-col>
                            <b-col cols="4" lg="5" class="summary-value">
                                ${{ Number(rateQuote ? rateQuote.TotalAnnualPremium : 0).toFixed(2) }}
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" md="6" lg="4" v-if="payment && payment.referenceNumber">
                        <b-row>
                            <b-col cols="7" lg="6" class="summary-label">
                                Payment Status:
                            </b-col>
                            <b-col cols="4" lg="5" class="summary-value">
                                <span>{{ payment && payment.status === 'ACCEPTED' ? 'Paid' : '' }}</span>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="7" lg="6" class="summary-label">
                                Policy Effective Date:
                            </b-col>
                            <b-col cols="4" lg="5" class="summary-value">
                                {{ policyEffectiveDate }}
                            </b-col>
                        </b-row>
                        <!-- TODO Only show if a policy was generated -->
                        <b-row v-if="false">
                            <b-col cols="7" lg="6" class="summary-label">
                                Policy Expiration Date:
                            </b-col>
                            <b-col cols="4" lg="5" class="summary-value">
                                {{ policyExpirationDate }}
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <!-- TODO Implement download/print application page -->
                        <b-row v-if="false">
                            <b-col cols="7" lg="6" class="summary-label">
                                Application:
                            </b-col>
                            <b-col cols="4" lg="5" class="summary-value">
                                <b-link id="DownloadApplicationPdf" class="underlined-link ga-UserInput" href="#">
                                    Download or Print
                                </b-link>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="7" lg="6" class="summary-label">
                                Enrolled in Auto Pay:
                            </b-col>
                            <b-col cols="4" lg="5" class="summary-value">
                                <template v-if="isAutopay">Yes</template>
                                <template v-else>No</template>
                            </b-col>
                        </b-row>
                        <b-row v-for="item in jewelryItems" :key="item.name">
                            <b-col cols="7" lg="6" class="summary-label">
                                <span>{{ item.name }}</span>
                                Appraisal:
                            </b-col>
                            <b-col cols="1" class="summary-value">
                                <template v-if="item.hasAppraisal">Yes</template>
                                <template v-else>No</template>
                            </b-col>
                            <b-col v-if="!item.hasAppraisal" cols="3" lg="4" class="summary-value">
                                <b-link
                                    class="ga-UserInput"
                                    target="_blank"
                                    id="uploadAppraisal-1"
                                    :href="appraisalUploadUrl"
                                >
                                    Upload
                                </b-link>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import Title from '@/components/Common/Title';

const DATE_FORMAT = 'MMMM DD, YYYY';

export default {
    name: 'Review',
    components: { Title },
    computed: {
        ...mapGetters(['hasAllAppraisals', 'getApplicant', 'jewelryItems', 'account', 'payment', 'rateQuote']),
        appraisalUploadUrl() {
            if (this.payment && this.payment.appraisalUploadUrl) {
                return this.payment.appraisalUploadUrl;
            }
            return '';
        },
        policyEffectiveDate() {
            if (!this.getApplicant.effectiveDate) {
                return '';
            }
            return moment(this.getApplicant.effectiveDate).format(DATE_FORMAT);
        },
        isAutopay() {
            return !!(this.payment && this.payment.autopay);
        },
        policyExpirationDate() {
            if (!this.getApplicant.effectiveDate) {
                return '';
            }
            return moment(this.getApplicant.effectiveDate)
                .add(1, 'year')
                .format(DATE_FORMAT);
        }
    },
    methods: {
        ...mapMutations(['resetState']),
        unloadEventListener() {
            this.resetState();
        }
    },
    mounted() {
        window.scrollTo(0, 0);
        window.addEventListener('unload', this.unloadEventListener);
        if (!this.account || !this.account.accountNumber) {
            this.$router.replace('/');
        }
    },
    unmounted() {
        window.removeEventListener('unload', this.unloadEventListener);
    },
    beforeRouteLeave(to, from, next) {
        if (to.meta && to.meta.inFlow && to.path !== '/') {
            // Prevent the user from going backwards in the flow once they have paid
            next(false);
            return;
        }
        next();
    }
};
</script>

<style lang="scss" scoped>
.jm-content-confirm {
    margin-bottom: 5em;
}
.insurance-details-title {
    font-size: 21px;
    padding: 20px 0;
}
.applicant-summary-content {
    font-size: 12px;
}
.summary-label {
    font-size: 12px;
    font-weight: bold;
}
.summary-value {
    font-size: 12px;
}
</style>
